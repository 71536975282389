@font-face {
  font-family: "TriodionUnicode"; /*Can be any text*/
  src: local("TriodionUnicode"),
    url("./font/TriodionUnicode.ttf") format("truetype");
}
body {
  font-family: "TriodionUnicode", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
}

.wrapper-class {
  border: 1px solid #ccc;
}
.editor-class {
  background-color: lightgray;

  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.code-view {
  margin: 10px;
}
.text-area {
  width: 100%;
  background: black;
  border: 1px solid #ccc;
  color: white;
  min-height: 200px;
}
