html {
  overflow-y: overlay !important;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  font-family: "TriodionUnicode", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
  overflow-y: overlay !important;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  background-color: #e8f2fc;
}

.dynamic-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.header-color {
  background-color: #e8f2fc;
}

#navbarBasicExample {
  background-color: #e8f2fc;
}

.table-radius {
  border-radius: 20px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* set your desired background color */
  padding: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 18px; /* adjust the size of the arrow icon */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  align-items: stretch;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

.nav {
  width: 200px;
  background-color: #f1f1f1;
}

.main {
  flex: 1;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.wrapperClassName {
  height: 500px;
}

/* Basic Styles for News Card */
.news-card {
  min-width: 800px; /* You can adjust this value */
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  max-height: 400px;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  display: flex; /* Ensure flex layout for responsiveness */
  margin-bottom: 1rem; /* Spacing between cards */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.news-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}

/* Container for the image */
.news-card-image {
  flex: 1 1 auto; /* Allow the image to grow and shrink */
  height: 100%; /* Allow height to adjust */
  padding-top: 20%; /* Maintain 16:9 aspect ratio */
  position: relative; /* Position relative to contain the image */
  overflow: hidden; /* Hide overflow */
  border-radius: 8px; /* Round corners */
}

.news-card-image img {
  position: absolute; /* Position absolute to fill the container */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  object-fit: contain; /* Ensure the whole image is visible */
  object-position: center; /* Center the image */
  transition: transform 0.3s ease; /* Smooth zoom effect on hover */
}

.news-card-image:hover img {
  transform: scale(1.05); /* Slight zoom on hover for images */
}

.news-card-content {
  flex: 1; /* Allow content to grow */
  padding: 1rem; /* Padding for content area */
}

/* Responsive Text */
.news-card h2 {
  font-size: 1.5rem; /* Base size for larger screens */
  margin-bottom: 0.5rem;
  font-weight: 600; /* Bold title */
  color: #333; /* Darker color for better contrast */
}

.news-card p {
  margin-bottom: 1rem;
  line-height: 1.6; /* Increased line height for readability */
  color: #555; /* Lighter color for description text */
}

/* Date styling */
.news-card-date {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #7a7a7a;
}

/* Read More link styling */
.news-card a {
  text-decoration: none; /* Remove underline */
  color: #007bff; /* Primary color for links */
  font-weight: 500; /* Slightly bold */
}
.mobile-visible {
  display: none !important;
}

.news-card a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-300p {
  height: 300px;
}

.conditions p {
  font-size: 1.1rem;
}

.conditions li {
  font-size: 1.1rem;
}

.donate p {
  font-size: 1.2rem;
}

.donate span {
  font-size: 1.2rem;
}

.background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-image: url("https://doronin.tomberg.tech/images/27.jpg"); /* Add your background image URL */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
}

.map {
  width: 300px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for the card in the top right */
.event-card {
  width: 300px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Flexbox Layouts */
@media (max-width: 768px) {
  /* Mobile devices */
  .news-card {
    flex-direction: column; /* Stack vertically on small screens */
  }

  .news-card-image {
    padding-top: 25%; /* Adjust aspect ratio for better fit on mobile */
  }

  .news-card h2 {
    font-size: 1.2rem; /* Smaller title size */
  }

  .news-card-date {
    font-size: 0.8rem; /* Smaller date size */
  }

  .news-card-content {
    padding: 0.5rem; /* Reduce content padding */
  }
  .mobile-hidden {
    display: none !important;
  }
  .mobile-visible {
    display: flex !important;
  }
  .event-card {
    position: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
  }
  .map {
    position: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: unset;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .news-card-image {
    flex-basis: 40%;
  }

  .news-card-content {
    flex-basis: 60%;
  }
}

@media (min-width: 1024px) {
  .news-card-image {
    flex-basis: 35%;
  }

  .news-card-content {
    flex-basis: 65%;
  }
}

/* Mobile-first default flex classes */

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

/* Flex Direction */
.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

/* Flex Wrap */
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

/* Justify Content */
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.self-scretch {
  align-self: stretch;
}

/* Align Items */
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

/* Flex Grow and Shrink */
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

/* Gap */
.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 2rem;
}

/* Flex Basis */
.basis-auto {
  flex-basis: auto;
}

.basis-full {
  flex-basis: 100%;
}

/* Media Queries */

/* Tablet (min-width: 769px) */
@media (min-width: 769px) {
  .tablet-flex-row {
    flex-direction: row;
  }

  .tablet-flex-column {
    flex-direction: column;
  }

  .tablet-justify-center {
    justify-content: center;
  }

  .tablet-justify-between {
    justify-content: space-between;
  }

  .tablet-items-center {
    align-items: center;
  }

  .tablet-flex-grow-1 {
    flex-grow: 1;
  }

  .tablet-gap-3 {
    gap: 1rem;
  }

  .tablet-basis-1-2 {
    flex-basis: 45%;
  }
}

@media screen and (max-width: 850px) {
  .news-card {
    min-width: fit-content;
  }
}

/* Desktop (min-width: 1024px) */
@media (min-width: 1024px) {
  .desktop-flex-row {
    flex-direction: row;
  }

  .desktop-flex-column {
    flex-direction: column;
  }

  .desktop-justify-center {
    justify-content: center;
  }

  .desktop-justify-between {
    justify-content: space-between;
  }

  .desktop-items-center {
    align-items: center;
  }

  .desktop-flex-grow-1 {
    flex-grow: 1;
  }

  .desktop-gap-3 {
    gap: 1rem;
  }

  .desktop-basis-1-3 {
    flex-basis: 33.33%;
  }
}

.navbar-brand .navbar-item {
  padding: 0;
}
.navbar-brand .navbar-item img {
  width: 48px;
  max-height: none;
  height: 48px;
}

.footer {
  padding: 2rem 1rem;
  background-color: #333; /* Dark background color */
  color: white; /* White text color */
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align items */
}

.logo {
  margin: 0 auto; /* Center the logo */
  margin-bottom: 1rem; /* Space below the logo */
}

.social-links {
  margin-top: 1rem;
}

.social-links .icon {
  margin: 0 10px;

  font-size: 1.5rem;
  transition: color 0.3s;
}

.insta:hover {
  color: #f5368f;
}
.fb:hover {
  color: #83a0de;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 0.5rem; /* Adjust padding for smaller screens */
  }

  .title {
    font-size: 1.5rem; /* Smaller title font size on mobile */
  }

  .social-links .icon {
    font-size: 1.2rem; /* Smaller icons on mobile */
  }
}

.bold {
  font-weight: bold;
}
.c-blue {
  color: blue;
}
.c-white {
  color: white;
}

.home-image {
  border-radius: 20px;
}

.prayer-form-container {
  max-width: 400px;
  margin: 0 auto; /* Center the form */
  padding: 20px;
  border: 2px solid #dbdbdb; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Base gap for mobile (default gap for small screens) */
.custom-gap {
  gap: 1rem;
}

/* Larger gap for tablets and up */
@media (min-width: 768px) {
  .custom-gap {
    gap: 2rem; /* Adjust as needed */
  }
}

/* Smaller gap for larger phones (max-width 576px) */
@media (max-width: 576px) {
  .custom-gap {
    gap: 0rem; /* Adjust for smaller phones */
  }
  .mobile-gap {
    padding: 0 !important;
  }
}
